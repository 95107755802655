<template>
    <div v-if="authUserPermission['kpi-mcr-creation-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('konsolidasi_report') }}</h3>
                        </div>
                        <div class="col-6">
                            <input type="text" class="form-control form-control-sm border-radius-20 text-center" v-model="search" :placeholder="tt('search')" v-on:keyup="filter"/>
                        </div>
                        <div class="col text-right">
                            <a :href="apiUrl + 'KonsolidasiReport/export?search='+search+'&token=' + token" class="btn btn-sm btn-outline-dark">
                                {{ tt('export_as_excel') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style="height: 500px">
                    <table class="table" v-if="!onLoad.table">
                        <thead>
                            <tr>
                                <th class="text-center">{{ tt('deletion_type') }}</th>
                                <th class="text-center">{{ tt('material_from') }}</th>
                                <th class="text-center">{{ tt('plant_from') }}</th>
                                <th class="text-center">{{ tt('storage_bin_from') }}</th>
                                <th class="text-center">{{ tt('unrestricted_stok_from') }}</th>
                                <th class="text-center">{{ tt('unit_price_from') }}</th>
                                <th class="text-center">{{ tt('material_to') }}</th>
                                <th class="text-center">{{ tt('plant_to') }}</th>
                                <th class="text-center">{{ tt('storage_bin_to') }}</th>
                                <th class="text-center">{{ tt('unrestricted_stok_from') }}</th>
                                <th class="text-center">{{ tt('unit_price_from') }}</th>
                                <th class="text-center">{{ tt('entry_date') }}</th>
                                <th class="text-center">{{ tt('posting_date') }}</th>
                                <th class="text-center">{{ tt('username') }}</th>
                                <th class="text-center">{{ tt('material_document') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in table.data">
                                <td class="text-center">{{row.deletion_type}}</td>
                                <td class="text-center">{{row.material_from}}</td>
                                <td class="text-center">{{row.plant_from}}</td>
                                <td class="text-center">{{row.storage_bin_from}}</td>
                                <td class="text-center">{{row.unrestricted_stok_from}}</td>
                                <td class="text-center">{{row.unit_price_from}}</td>
                                <td class="text-center">{{row.material_to}}</td>
                                <td class="text-center">{{row.plant_to}}</td>
                                <td class="text-center">{{row.storage_bin_to}}</td>
                                <td class="text-center">{{row.unrestricted_stok_from}}</td>
                                <td class="text-center">{{row.unit_price_from}}</td>
                                <td class="text-center">{{(row.entry_date == NULL) ? '0000-00-00' : row.entry_date}}</td>
                                <td class="text-center">{{(row.posting_date == NULL) ? '0000-00-00' : row.posting_date}}</td>
                                <td class="text-center">{{row.username}}</td>
                                <td class="text-center">{{row.material_document}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <skeleton-loading v-else/>
                </div>
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from "vuex";
    import Api from '@/helpers/api';
    import konolidasiReport from '@/services/report/konsolidasiReport.service';
    import config from '@/configs/config';

    export default {
        data() {
            return {
                date: '',
                table: {
                    data: [],
                    total: 0,
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },
                onLoad: {
                    table: true
                },
                search : (this.$route.query.search) ? this.$route.query.search : '',
                apiUrl: config.apiUrl,
                token: localStorage.getItem('token')
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            const today = new Date();
            this.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            this.get();
        },
        methods: {
            filter() {
                if(this.search != this.$route.query.search){
                    this.onLoad.table = true;

                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.search == ''){
                            this.$router.push({path:'/report/konsolidasi-report', query:null})
                        }else{
                            this.$router.push({path:'/report/konsolidasi-report', query:{search:this.search}})
                        }
                        this.get();
                    }, 100);
                }
            },
            get() {
                let context = this;
                Api(context, konolidasiReport.index(this.table.page.current_page, {search: this.search})).onSuccess(function(response) {
                    context.table.data   = response.data.data.data.data;
                    context.table.data   = response.data.data.data.data;
                    context.table.page   = response.data.data.data;
                    context.onLoad.table = false;
                }).onError(function(error) {
                    context.table.data   = [];
                    context.table.total  = 0;
                    context.onLoad.table = false;
                }).call()
            },
            changePage(page) {
                let context = this;
                context.onLoad.table = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            }
        }
    };
</script>
